<template>
  <DPHeader titlu="The challenge to a healthy life"/>
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <h5>Activity type</h5>
        <div class="list-group mb-3">
            <router-link :to="`/`" class="list-group-item d-flex justify-content-between align-items-center" :class="{active: filter_activity_type == ''}">
                <span>All activities</span>
                <i class="fas fa-check" v-if="filter_activity_type == ''"></i>
            </router-link>
            <router-link :to="`/${C.link}`" class="list-group-item d-flex justify-content-between align-items-center" :class="{active: filter_activity_type == C.name}" v-for="C,I in activity_types" :key="I">
              <span>{{C.label}}</span>
              <i class="fas fa-check" v-if="filter_activity_type == C.name"></i>
            </router-link>
        </div>
        <h5>Country</h5>
        <div class="list-group mb-3">
            <a href @click.prevent="filter_country=``"  class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-center" :class="{active: filter_country == ``}">
              <span>All countries</span>
              <i class="fas fa-check" v-if="filter_country==''"></i>
            </a>
            <a href @click.prevent="filter_country==C?filter_country = ``: filter_country = C"  class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-center" :class="{active: filter_country == C}" v-for="C,I in countries" :key="I">
              <span>{{C}}</span>
              <i class="fas fa-check" v-if="filter_country==C"></i>
            </a>
        </div>
        <div class="rounded border mb-3 p-2 text-center text-muted">
          <div class="h1">{{number_of_results}}</div>
          <p>{{number_of_results == 1?"activity":"activities"}}</p>
        </div>
        <div class="mb-3 text-end">
          <div class="btn-group">
            <a href="#" class="btn btn-primary" :class="{active: sort_ascending}" @click="sort_ascending = true">
              <i class="fas fa-sort-amount-up" v-if="sort_ascending"></i>
              Oldest first
            </a>
            <a href="#" class="btn btn-primary" :class="{active: !sort_ascending}" @click="sort_ascending = false">
              <i class="fas fa-sort-amount-down"  v-if="!sort_ascending"></i>
              Newest first
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <CardList :filter_type="filter_type" :filter_country="filter_country" :filter_activity_type="filter_activity_type" :sort_ascending="sort_ascending" @on_number_of_activities_changed="number_of_results = $event"/>
      </div>
    </div>
  </div>
</template>

<script>
import CardList from './CardList.vue'
import DPHeader from './DPHeader.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'HomePage',
  components: {
    CardList,
    DPHeader
  },
  data(){
    return {
      filter_type: '',
      filter_country: '',
      filter_activity_type: '',
      sort_ascending: false,
      order: {
        criteria: 'date',
        ascendent: false,
      },
      number_of_results: 0,
      countries: ['Croatia', 'Italia', 'North Macedonia', 'Romania', 'Slovakia', 'Turkey', 'Transnational'],
      activity_types:[
        {
            name:"Presentation/Conference",
            label:"Presentations",
            link:"presentations"
        },
        {
            name:"Workshop",
            label:"Workshops",
            link:"workshops"
        },
        {
            name:"Event",
            label:"Public event",
            link:"events"
        },
        {
            name:"Other activity",
            label:"Other activity",
            link:"other-activities"
        },
        {
            name:"Dissemination (article, radio, TV, social media)",
            label:"Dissemination",
            link:"disseminations"
        },
        {
            name:"Lessons or Subject",
            label:"Lessons or Subject",
            link:"lessons"
        },
        {
            name:"Multiplier event or Follow up event",
            label:"Multiplier/Follow up event",
            link:"multipliers"
        },


      ],
    }
  },
  mounted(){
    const route = useRoute()
    if(route.matched.length)
    {
        this.activity_types.forEach(element => {
            if(element.link == route.matched[0].name)
                this.filter_activity_type = element.name
        });
    }
    this.filter_country = ""
  }
}
</script>

<style lang="scss" scoped>

</style>
