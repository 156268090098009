<template>
    <div class="bg-secondary mb-4">
        <div class="container">
            <h1 class="py-5 text-warning text-center">
                {{titlu}}
            </h1>
            <h2 class="pb-5 text-light text-center" v-if="subtitlu">
                {{subtitlu}}
            </h2>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DPHeader',
    props: {
        titlu: String,
        subtitlu: String,
    }
}
</script>
