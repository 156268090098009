import { createWebHistory, createRouter } from "vue-router"
import HomePage from "@/components/Home.vue"
import DespreProiect from "@/components/DespreProiect.vue"
import PageActivity from "@/components/PageActivity.vue"
import PageNotFound from "@/components/PageNotFound.vue"

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/presentations",
        name: "presentations",
        component: HomePage,
    },
    {
        path: "/workshops",
        name: "workshops",
        component: HomePage,
    },
    {
        path: "/events",
        name: "events",
        component: HomePage,
    },
    {
        path: "/other-activities",
        name: "other-activities",
        component: HomePage,
    },
    {
        path: "/disseminations",
        name: "disseminations",
        component: HomePage,
    },
    {
        path: "/lessons",
        name: "lessons",
        component: HomePage,
    },
    {
        path: "/multipliers",
        name: "multipliers",
        component: HomePage,
    },
    {
        path: '/despre',
        name: "Despre",
        component: DespreProiect
    },
    {
        path: '/activity/:id',
        name: "PageActivity",
        component: PageActivity
    },
    {
        path:"/:catchAll(.*)*",
        name: "PageNotFound",
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router