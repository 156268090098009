<template>
  <DPHeader titlu="Pagina nu există"/>
  <p>
      Pagina cerută nu există!
  </p>
</template>

<script>
import DPHeader from './DPHeader.vue'

export default {
  name: 'HomePage',
  components: {
    DPHeader
  }
}
</script>

<style lang="scss" scoped>

</style>
