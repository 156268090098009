<template>
  <MeniuNavigare />
  <router-view :key="this.$route.path" />
</template>

<script>
import MeniuNavigare from '@/components/MeniuNavigare.vue'

export default {
  name: 'App',
  components: {
    MeniuNavigare
  },
  data(){
    return {
      
    }
  }
}
</script>

<style scoped>

</style>
