<template>
    <div class="d-flex-row justify-content-between align-items-center vh-100">
        <DPHeader titlu="About website"/>
        <div class="container">
            <p class="mt-5 pt-5">
                This site was made for "The challenge to a healthy life" project.
            </p>
            <p>
                Author: <em>Silviu Candale</em> - Colegiul Național Liviu Rebreanu, Bistrița
            </p>
        </div>
    </div>
</template>

<script>
import DPHeader from './DPHeader.vue'

export default {
  name: 'DespreProiect',
  components: {
    DPHeader
  }
}
</script>

<style lang="scss" scoped>

</style>
