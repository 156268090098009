<template>
    <div class="card mb-4">
        <div class="card-header">
            <h4 class="card-tile">
            <router-link :to="`/activity/`+data.id" :title="data.title + ` - ` + data.type">
                {{data.title.length == 0 ? data.id : (data.title.length > 23? SentenceCase(data.title).slice(0 , 20) + '...' : SentenceCase(data.title))}}
            </router-link>
            </h4>
        </div>
        <div class="card-body">
            <h6 class="card-subtitle mb-2 text-muted">
                <div class="d-flex justify-content-between">
                    <div>
                        {{data.type}}
                    </div>
                    <div>
                        {{data.country}}
                    </div>
                </div>
            </h6>
            <div class="text-center position-relative">
                <router-link :to="`/activity/`+data.id">
                    <img class="img-fluid border p-2" :src="`http://chl.cnlr.ro/preview/` + DriveId(data.links[0])" v-if="data.links[0]">
                    <img v-else src="img-not-available.jpg" class="img-fluid border p-2">
                </router-link>
            </div>
        </div>
        <div class="card-footer text-muted">
            <div class="d-flex justify-content-between">
                <div class="card-text me-2" :title="`Activity date: ` + data.date">
                    <small style="white-space: nowrap;"><i class="fas fa-calendar"></i>&nbsp;{{data.date.getFullYear()}}-{{data.date.getMonth()+1}}-{{data.date.getDate()}}</small>
                </div>
                <div class="card-text" title="Organiser">
                    <small>{{data.organiser}}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Fancybox} from "@fancyapps/ui/src/Fancybox/Fancybox"

export default {
  name: 'CardSingle',
  props: {
    data: Object
  },
  components:{
    
  },
  methods:{
    TitleCase(V){
        if(! V)
            return '';
        return V.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    },
    SentenceCase(V){
        if(! V)
            return '';
        return V.charAt(0).toUpperCase() + V.slice(1);
    },
    DrivePreview(url){
        let A = url.slice(url.indexOf('id=') + 3);
        return `https://drive.google.com/thumbnail?id=` +A;

    },
    DriveId(url){
        return url.slice(url.indexOf('id=') + 3);
    }

  },
  mounted(){
    Fancybox.bind("[data-fancybox]", {});
  }
}
</script>

<style scoped>
@import "@fancyapps/ui/dist/fancybox.css";

.play-button{
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 3;
    background-image: url('../assets/bg-play.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0 0;
    height: 68px;
    margin: -42px 0 0 -34px;
    width: 68px;
}
</style>