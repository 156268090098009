<template>
    <div v-if="activity">
        <DPHeader :titlu=" (activity.title.length > 53? SentenceCase(activity.title).slice(0 , 50) + '...' : SentenceCase(activity.title))"/>
        <div class="container">
            <div class="p-3 text-end mb-3" style="background-color: #eee;">
                <router-link :to="`/activity/`+prev_activity.id" v-if="prev_activity" :title="SentenceCase(prev_activity.title)" class="btn btn-info">
                    <i class="fas fa-caret-left"></i>
                </router-link>
                <a v-else class="btn btn-secondary">
                    <i class="fas fa-caret-left"></i>
                </a>
                <span class="m-2"></span>
                <router-link :to="`/activity/`+next_activity.id" v-if="next_activity" :title="SentenceCase(next_activity.title)" class="btn btn-info">
                    <i class="fas fa-caret-right"></i>
                </router-link>
                <a v-else class="btn btn-secondary">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <p class="mb-3">
                        Activity type: <strong>{{activity.type}}</strong>
                    </p>
                    <p class="mb-3">
                        Date: <strong>{{activity.date.getFullYear() + "-" + (activity.date.getMonth()+1) + "-" + activity.date.getDate()}}</strong>
                        
                    </p>
                    <p class="mb-3">
                        Country: <strong>{{activity.country}}</strong>
                    </p>
                    <p class="mb-3" v-if="activity.organiser">
                        Organiser: <strong>{{activity.organiser}}</strong>
                    </p>
                    <p class="mb-3" v-if="activity.number_of_participants">
                        Number of participants: <strong>{{activity.number_of_participants}}</strong>
                    </p>
                    <p class="mb-3" v-if="activity.guests">
                        Guests: <strong>{{activity.guests}}</strong>
                    </p>


                    <div class="list-group" v-if="false">
                        <router-link :to="`/activity/`+L.id" v-for="L in cards" :key="L.id" class="list-group-item" :class="{active: L.id==activity.id}">
                            {{SentenceCase(L.title)}}
                        </router-link>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-4 py-3" v-if="activity.activity">
                        <h5 class="text-muted border-bottom">Activity</h5>
                        <div class="ps-3">
                            {{activity.activity}}
                        </div>
                    </div>
                    <div class="mb-4 py-3" v-if="activity.description">
                        <h5 class="text-muted border-bottom">Description</h5>
                        <div class="ps-3">
                            {{activity.description}}
                        </div>
                    </div>

                    <div class="mb-4 py-3" v-if="activity.details">
                        <h5 class="text-muted border-bottom">Details</h5>
                        <div class="ps-3" v-html="activity.details">
                            
                        </div>
                    </div>

                    <div class="mb-4 py-3">
                        <h5 class="text-muted border-bottom">Proofs</h5>
                        <div v-if="activity.links[0] || activity.youtube_videos[0]">
                            <div class="row mb-3" v-if="true">
                                <div class="col-lg-4" v-for="L in activity.links" :key="L.id">
                                    <a :href="L" target="_blank" v-if="L">
                                        <img class="img-fluid border p-2" :src="`http://chl.cnlr.ro/preview/` + DriveId(L)">
                                    </a>
                                </div>
                            </div>
                            <ul v-if="activity.links[0]">
                                <li v-for="L in activity.links" :key="L.id">
                                    <a :href="L" target="_blank">
                                        {{L}}
                                    </a>
                                </li>
                            </ul>
                            <div v-if="activity.youtube_videos.length && activity.youtube_videos[0]" class="p-1" style="background-color: #f6aba2;">
                                <ul class="m-0">
                                    <li v-for="L in activity.youtube_videos" :key="L.id">
                                        <a v-if="L" :href="L" target="_blank">{{L}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else class="alert alert-warning">
                            No link available.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="d-flex justify-content-center align-items-center vh-100">
            <div class="text-warning" style="font-size: 6rem;">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
    </div>
</template>

<script>

import DPHeader from '@/components/DPHeader.vue'
import {Fancybox} from "@fancyapps/ui/src/Fancybox/Fancybox"
import axios from 'axios'
import { useRoute } from 'vue-router'

export default {
    name: 'PageActivity',
    components: {
        DPHeader
    },
    setup() {
        
    },
    data(){
        return {
            cards: [],
            route_activity_id: -1,
            activity:undefined,
            next_activity: undefined,
            prev_activity: undefined,
        }
    },
    computed:{
        
    },
    created(){
        const route = useRoute()
        this.route_activity_id = route.params.id
        
        this.loadActivities()
        this.cards.forEach(element => {
            if(element.id == this.route_activity_id)
            {
                this.activity = element
            }
        });
        if(this.activity)
        {
            this.cards.forEach(element => {
                if(element.id == this.activity.id - 1)
                {
                    this.prev_activity = element
                }
                if(element.id == this.activity.id + 1)
                {
                    this.next_activity = element
                }
            });
            // this.activity.description = this.urlify(this.activity.description)
        }
    },
    mounted(){
        Fancybox.bind("[data-fancybox]", {});
    },
    methods:{
        urlify(text) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, function(url) {
                return '<a href="' + url + '">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        TitleCase(V){
            if(! V)
                return '';
            return V.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
        },
        SentenceCase(V){
            if(! V)
                return '';
            return V.charAt(0).toUpperCase() + V.slice(1);
        },
        DrivePreview(url){
            let A = url.slice(url.indexOf('id=') + 3);
            return `https://drive.google.com/thumbnail?id=` +A;

        },
        DriveId(url){
            return url.slice(url.indexOf('id=') + 3);
        },
        loadActivities(){
            let T = sessionStorage.getItem('cards')
            if(T)
            {
                T = JSON.parse(T);
                for(let i = 0 ; i < T.length ; i ++)
                {
                    T[i].date = new Date(T[i].date)
                }
                this.cards = T
                return
            }
            axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vRc25zo0eL6jCBeBaZ-fw70OA3qEUCKXCGJp9R4fV2wkxmekV2SdNK5ntAl-eQf0bFYKeKLLiL1M0qs/pub?output=tsv").then(response => {
                let T = [];
                response.data.split('\n').forEach((element , index) => {
                    if(index > 0)
                    {
                        let V = element.split('\t');
                        if(V[1] == 'Presentation/Conference')
                                    {
                                        T.push({
                                            id: index,
                                            type: V[1],
                                            country: V[2],
                                            activity: V[3],
                                            date: new Date(V[4].split('.').reverse().join('-')),
                                            title: V[5],
                                            organiser: V[6],
                                            number_of_participants: parseInt(V[7]),
                                            guests: V[8],
                                            links: V[9].split(','),
                                            youtube_videos: V[10]?V[10].split(','):[]
                                        })
                                    }
                                    if(V[1] == 'Workshop')
                                    {
                                        T.push({
                                            id: index,
                                            type: V[1],
                                            country: V[11],
                                            activity: V[12],
                                            date: new Date(V[13].split('.').reverse().join('-')),
                                            title: V[14],
                                            organiser: V[15],
                                            number_of_participants: parseInt(V[16]),
                                            guests: '',
                                            links: V[17].split(','),
                                            youtube_videos: V[18]?V[18].split(','):[]
                                        })
                                    }
                                    if(V[1] == 'Public event (Special day, sport event and so on)')
                                    {
                                        T.push({
                                            id: index,
                                            type: 'Event',
                                            country: V[19],
                                            activity: V[20],
                                            date: new Date(V[21].split('.').reverse().join('-')),
                                            title: V[22],
                                            organiser: V[23],
                                            number_of_participants: parseInt(V[24]),
                                            guests: '',
                                            links: V[25].split(','),
                                            youtube_videos: V[26]?V[26].split(','):[]
                                        })
                                    }
                                    if(V[1] == 'Other activity')
                                    {
                                        T.push({
                                            id: index,
                                            type: V[1],
                                            country: V[27],
                                            activity: '',
                                            date: new Date(V[28].split('.').reverse().join('-')),
                                            title: V[29],
                                            organiser: V[30],
                                            number_of_participants: parseInt(V[32]),
                                            guests: '',
                                            // details: V[31],
                                            description: V[31],
                                            links: V[33].split(','),
                                            youtube_videos: V[34]?V[34].split(','):[]
                                        })
                                    }
                                    if(V[1] == 'Dissemination (article, radio, TV, social media)')
                                    {
                                        T.push({
                                            id: index,
                                            type: V[1],
                                            country: V[35],
                                            activity: '',
                                            date: new Date(V[36].split('.').reverse().join('-')),
                                            title: V[38], //'Dissemination (article, radio, TV, social media)',
                                            organiser: V[37],
                                            number_of_participants: 0,
                                            description: V[38],
                                            links: V[39].split(','),
                                            youtube_videos: V[40]?V[40].split(','):[]
                                        })
                                    }
                                    if(V[1] == 'Lessons or Subject')
                                    {
                                        T.push({
                                            id: index,
                                            type: V[1],
                                            country: V[41],
                                            activity: '',
                                            date: new Date(V[42].split('.').reverse().join('-')),
                                            title: V[43],
                                            organiser: V[44],
                                            number_of_participants: V[46],
                                            description: V[45],
                                            links: V[47].split(','),
                                            youtube_videos: V[48]?V[48].split(','):[]
                                        })
                                    }
                                    if(V[1] == 'Multiplier event or Follow up event')
                                    {
                                        T.push({
                                            id: index,
                                            type: V[1],
                                            country: V[49],
                                            activity: '',
                                            date: new Date(V[50].split('.').reverse().join('-')),
                                            title: V[51],
                                            organiser: V[52],
                                            number_of_participants: V[53],
                                            description: V[51],
                                            links: V[54].split(','),
                                            youtube_videos: V[55]?V[55].split(','):[]
                                        })
                                    }
                        }
                    });
                this.cards = T;
                sessionStorage.setItem('cards', JSON.stringify(T))
                this.cards.forEach(element => {
                    if(element.id == this.route_activity_id)
                    {
                        this.activity = element
                        console.log(element)
                    }
                });
                if(this.activity)
                {
                    this.cards.forEach(element => {
                        if(element.id == this.activity.id - 1)
                        {
                            this.prev_activity = element
                        }
                        if(element.id == this.activity.id + 1)
                        {
                            this.next_activity = element
                        }
                    });
                    // this.activity.description = this.urlify(this.activity.description)
                }
            })
        }
    }
}
</script>

<style scoped>
    
</style>
