<template>
  <div v-if="!loading">
    <div class="mb-3">
      <input v-model="filter" class="form-control" placeholder="Filter activities ">
    </div>
    <div class="row">
      <div class="col-sm-4 col-md-4" v-for="card in CardsList" :key="card.id">
        <CardSingle :data="card"/>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="text-warning" style="font-size: 6rem;">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<script>
import CardSingle from './CardSingle.vue'
// import "@fancyapps/ui/dist/fancybox.css";
// import Fancybox from "@fancyapps/ui";

import axios from 'axios'

export default {
  emits:['on_number_of_activities_changed'],
  methods:{
    number_of_activities_changed(){
      this.$emit('on_number_of_activities_changed', this.number_of_results);
    },
    loadActivities(){
        let T = sessionStorage.getItem('cards')
        if(T)
        {
            T = JSON.parse(T);
            for(let i = 0 ; i < T.length ; i ++)
            {
                T[i].date = new Date(T[i].date)
            }
            this.cards = T
            this.loading = false;
            return
        }
        axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vRc25zo0eL6jCBeBaZ-fw70OA3qEUCKXCGJp9R4fV2wkxmekV2SdNK5ntAl-eQf0bFYKeKLLiL1M0qs/pub?output=tsv").then(response => {
        let T = [];
        response.data.split('\n').forEach((element , index) => {
            if(index > 0)
            {
            let V = element.split('\t');
            if(V[1] == 'Presentation/Conference')
                        {
                            T.push({
                                id: index,
                                type: V[1],
                                country: V[2],
                                activity: V[3],
                                date: new Date(V[4].split('.').reverse().join('-')),
                                title: V[5],
                                organiser: V[6],
                                number_of_participants: parseInt(V[7]),
                                guests: V[8],
                                links: V[9].split(','),
                                youtube_videos: V[10]?V[10].split(','):[]
                            })
                        }
                        if(V[1] == 'Workshop')
                        {
                            T.push({
                                id: index,
                                type: V[1],
                                country: V[11],
                                activity: V[12],
                                date: new Date(V[13].split('.').reverse().join('-')),
                                title: V[14],
                                organiser: V[15],
                                number_of_participants: parseInt(V[16]),
                                guests: '',
                                links: V[17].split(','),
                                youtube_videos: V[18]?V[18].split(','):[]
                            })
                        }
                        if(V[1] == 'Public event (Special day, sport event and so on)')
                        {
                            T.push({
                                id: index,
                                type: 'Event',
                                country: V[19],
                                activity: V[20],
                                date: new Date(V[21].split('.').reverse().join('-')),
                                title: V[22],
                                organiser: V[23],
                                number_of_participants: parseInt(V[24]),
                                guests: '',
                                links: V[25].split(','),
                                youtube_videos: V[26]?V[26].split(','):[]
                            })
                        }
                        if(V[1] == 'Other activity')
                        {
                            T.push({
                                id: index,
                                type: V[1],
                                country: V[27],
                                activity: '',
                                date: new Date(V[28].split('.').reverse().join('-')),
                                title: V[29],
                                organiser: V[30],
                                number_of_participants: parseInt(V[32]),
                                guests: '',
                                details: V[31],
                                links: V[33].split(','),
                                youtube_videos: V[34]?V[34].split(','):[]
                            })
                        }
                        if(V[1] == 'Dissemination (article, radio, TV, social media)')
                        {
                            T.push({
                                id: index,
                                type: V[1],
                                country: V[35],
                                activity: 'Dissemination (article, radio, TV, social media)',
                                date: new Date(V[36].split('.').reverse().join('-')),
                                title: V[38], //'Dissemination (article, radio, TV, social media)',
                                organiser: V[37],
                                number_of_participants: 0,
                                description: '',
                                links: V[39].split(','),
                                youtube_videos: V[40]?V[40].split(','):[]
                            })
                        }
                        if(V[1] == 'Lessons or Subject')
                        {
                            T.push({
                                id: index,
                                type: V[1],
                                country: V[41],
                                activity: '',
                                date: new Date(V[42].split('.').reverse().join('-')),
                                title: V[43],
                                organiser: V[44],
                                number_of_participants: V[46],
                                description: V[45],
                                links: V[47].split(','),
                                youtube_videos: V[48]?V[48].split(','):[]
                            })
                        }
                        if(V[1] == 'Multiplier event or Follow up event')
                        {
                            T.push({
                                id: index,
                                type: V[1],
                                country: V[49],
                                activity: '',
                                date: new Date(V[50].split('.').reverse().join('-')),
                                title: V[51],
                                organiser: V[52],
                                number_of_participants: V[53],
                                description: V[51],
                                links: V[54].split(','),
                                youtube_videos: V[55]?V[55].split(','):[]
                            })
                        }
            }
        });
        this.cards = T;
        sessionStorage.setItem('cards', JSON.stringify(T))
        this.loading = false;
        })
    }
  },
  name: 'CardList',
  components:{
    CardSingle
  },
  props: {
    filter_type: String,
    filter_country: String,
    sort_ascending: Boolean,
    filter_activity_type: String,
  },
  data(){
    return {
      cards: [],
      filter:'',
      number_of_results: 0,
      loading: true,
    }
  },
  computed:{
    CardsList(){
      let T = [];
      this.cards.forEach(element => {
        if(element.type.toLowerCase().indexOf(this.filter_type.toLowerCase()) !== -1 
            && element.country.toLowerCase().indexOf(this.filter_country.toLowerCase()) !== -1
            && element.type.toLowerCase().indexOf(this.filter_activity_type.toLowerCase()) !== -1
            && (element.organiser.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 
                  || element.activity.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 
                //  || element.title.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1
                )
          )
          T.push(element);
      });
      return T.sort((a,b)=>{
        if(this.sort_ascending)
        {
          if(a.date < b.date)
            return -1;
          else
            if(a.date > b.date)
              return 1;
            else
              return 0;
        }
        if(a.date < b.date)
          return 1;
        else
          if(a.date > b.date)
            return -1;
          else
            return 0;
      });
    }
  },
  watch:{
    CardsList:{
      deep: true,
      handler: function(V){
        this.number_of_results = V.length;
        this.number_of_activities_changed();
      }
    }
  },
  mounted(){
    // data.json
    //https://docs.google.com/spreadsheets/d/e/2PACX-1vQSkHtAhabZFMY4e_DmZocJkOVLaRfMYnXfMvgv-BM0L2MFNnsjSd1o-LW3JICsqsGE8uh8Ac2AcN5g/pub?output=tsv
    this.loadActivities()
  },
  
}
</script>
